export function exampleSwiper(id = 'swiper', pagination = 'example-pagination', delay = 6000) {
  if ($(`#${id}`).length < 1) {
    return;
  }
  const example = new Swiper(`#${id}`, {
    autoplay: {
      delay
    },
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: `.${pagination}`,
      type: 'custom',
      clickable: true,
      renderCustom(swiper, current) {
        $(`.${pagination}`)
          .children()
          .map((index, el) => {
            if (current - 1 === index) {
              $(el).addClass('active');
            } else {
              $(el).removeClass('active');
            }
            return '';
          });
      }
    }
  });
  const active = (e) => {
    const clicked = $(e.target).data('index');
    if (clicked) {
      example.slideToLoop(clicked - 1);
    }
  };
  $(`.${pagination}`)
    .on('click', active)
    .on('mouseover', active);
}

export function addActive(target, siblings) {
  if (!target || target.dataset.noactive) {
    return;
  }
  target = $(target);
  if (!target.hasClass('active')) {
    target
      .addClass('active')
      .siblings(siblings)
      .removeClass('active');
  }
}

export function formatFloat(n) {
  const float4 = parseFloat(n).toFixed(4);
  if (float4.toString().endsWith('00')) {
    return parseFloat(n).toFixed(2);
  }
  if (float4.toString().endsWith('0')) {
    return parseFloat(n).toFixed(3);
  }
  return float4;
}
export function formatCurrency(n) {
  const parts = formatFloat(n)
    .toString()
    .split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function localStorageSet(key, value) {
  if (typeof value === 'object') {
    value.date = Date.now();
    value = JSON.stringify(value);
  } else {
    value = JSON.stringify({
      date: Date.now(),
      value
    });
  }
  localStorage.setItem(key, value);
}

export function localStorageGet(key) {
  const localStore = JSON.parse(localStorage.getItem(key));
  if (localStore) {
    const { value, date } = localStore;
    const updateSince = (Date.now() - date) / 1000;
    // If exchange rate is missing due to network or other issues
    if (typeof value === 'undefined') {
      return false;
    }
    // If updated within 1 day (86400 secs)
    if (updateSince > 86400) {
      return false;
    }
    return value;
  }
  return false;
}
const headerHeight = 58;
export function navInit() {
  const navElement = $('.fixed-nav-section');
  // nav section init
  const showNav = () => {
    if (document.documentElement.scrollTop - document.body.scrollTop > headerHeight) {
      navElement.addClass('show');
    } else {
      navElement.removeClass('show');
    }
  };
  let currentActiveElement;
  const activateElement = (element) => {
    if (element === currentActiveElement) {
      return;
    }
    $(element)
      .addClass('active')
      .siblings('a')
      .removeClass('active');
    currentActiveElement = element;
  };
  showNav();
  if (navElement.length > 0) {
    const { hash } = window.location;
    const hashElement = $(`a[href^="${hash}"]`, '.fixed-nav-section ');
    if (hash && hashElement.length > 0) {
      activateElement(hashElement);
    }
    const content = [];
    $('a[href^="#"]', '.fixed-nav-section').each(function () {
      const href = $(this).attr('href');
      if ($(href).length > 0) {
        content.unshift({
          element: this,
          top: $(href).offset().top
        });
      }
    });

    $(document).scroll(() => {
      showNav();
      const top = window.pageYOffset;
      const currentElement = content
        .find((elementInfo) => elementInfo.top < top + headerHeight + 1);
      if (currentElement) {
        activateElement(currentElement.element);
      }
    });
  }
  // eslint-disable-next-line no-new
  new SmoothScroll('a[href*="#"]', {
    offset: headerHeight
  });
}
