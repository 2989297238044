function getCardParams(method) {
  let link;
  if (typeof method !== 'string') {
    // eslint-disable-next-line prefer-destructuring
    link = method.link;
    method = method.name;
  }
  const parmas = window.LC_SUPPORT_METHOD ? window.LC_SUPPORT_METHOD[method] : undefined;
  if (!parmas) {
    return '';
  }
  if (link) {
    parmas.link = link;
  }
  parmas.target = parmas.link.indexOf('tel:') > -1 || parmas.link.indexOf('mailto:') > -1 ? '_self' : '_blank';
  return parmas;
}

function getSuportTmp(questions, query) {
  const data = {
    name: query
  };
  if (questions) {
    data.name = questions.name;
    data.content = questions.answer.map((item) => ({
      details: Array.isArray(item.content) ? item.content : [item.content],
      cards: item.methods ? item.methods.map((method) => getCardParams(method)) : []
    }));
  }
  return artTemplate.render(
    `
  <section class="section-contain ">
    <h4 class="title">
      <a href="/support/" class="back-list small"><i class="uk-icon-angle-left"></i> 显示全部分类</a>
      ${questions.description}
    </h4>

    {{if data.content}}
       {{each  data.content}}
          {{if $value.details.length > 1 }}
             <div class="multi-content ">
              {{each $value.details}}
                <p>{{@ $value }}</p>
              {{/each}}
             </div>
          {{else }}
            {{each $value.details}}
                <p>{{@ $value }}</p>
            {{/each}}
          {{/if}}
          <div class="question-cards">
            {{each $value.cards}}
                 <div class="question-card ">
                  <a href="{{$value.link}}" class="content" target={{$value.target}}>
                    <h5 class="title">{{$value.title}}</h5>
                    <p class="small text-muted">{{@ $value.content}}</p>
                  </a>
                </div>
            {{/each}}
          </div>
      {{/each}}
    {{/if}}
    {{if !data.content}}
      <p>问题不在范围之内，请返回分类重新选择<p>
    {{/if}}
  </section>`,
    { data }
  );
}

export default function support() {
  const search = new URLSearchParams(window.location.search);
  const query = search.get('q');
  if (query) {
    $('#question-list').hide();
    const questions = window.LC_SUPPORT_QUSTIONS.find((question) => question.description === query);
    $('#question-container').html(getSuportTmp(questions, query));
    $('#question-container').show();
  } else {
    $('#question-list').show();
    $('#question-container').hide();
  }
}
